@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Greeting";
  src: url('./data/Fonts/lemon_milk/LEMONMILK-Medium.otf');
}

@font-face {
  font-family: "Porter";
  src: url('./data/Fonts/Porter\ Bold.ttf');
}

@font-face  {
    font-family: 'Barlow';
    src: url('./data/Fonts/Barlow/Barlow-Regular.ttf');
  }

@font-face  {
  font-family: 'Roboto-Regular';
  src: url('./data/Fonts/Roboto/Roboto-Regular.ttf');
}

@font-face  {
  font-family: 'Roboto-Bold';
  src: url('./data/Fonts/Roboto/Roboto-Bold.ttf');
}

@font-face  {
  font-family: 'Lexend-Deca-Regular';
  src: url('./data/Fonts/Lexend_Deca/LexendDeca-VariableFont_wght.ttf');
}

